import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {WithResultObserver} from '@wix/function-result-observation/dist/types/worker/withResultObserver.worker';
import {confirmPayment} from '@wix/ecom-platform-express-checkout-buttons';
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/src/types/PaymentsWidgetConfiguration';
import {StoresWidgetID} from '@wix/wixstores-client-core';
import {CashierMandatoryFieldsOverrides, PaymentStoreProps} from '../../types/app.types';
import {CheckoutService} from '../services/CheckoutService';
import {NavigationService} from '../services/NavigationService';
import {MemberService} from '../services/MemberService';
import {getCurrentLanguage} from '../utils/ambassador.utils';
import {PaymentService} from '../services/PaymentService';
import {getCountryFromLocaleDataset} from '../utils/localeDataset.util';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {SPECS} from '../../components/Checkout/constants';

export type PaymentStoreConfig = {
  flowAPI: ControllerFlowAPI;
  checkoutService: CheckoutService;
  checkoutSettingsService: CheckoutSettingsService;
  navigationService: NavigationService;
  memberService: MemberService;
  paymentService: PaymentService;
  observe: WithResultObserver;
  updateComponent: () => void;
};

export class PaymentStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly navigationService: NavigationService;
  private readonly memberService: MemberService;
  private readonly paymentService: PaymentService;
  private readonly updateComponent: () => void;
  private readonly observe: WithResultObserver;

  constructor({
    flowAPI,
    checkoutService,
    checkoutSettingsService,
    navigationService,
    memberService,
    paymentService,
    observe,
    updateComponent,
  }: PaymentStoreConfig) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.navigationService = navigationService;
    this.memberService = memberService;
    this.updateComponent = updateComponent;
    this.paymentService = paymentService;
    this.observe = observe;
  }

  private readonly setCashierMandatoryFields = (cashierMandatoryFields: CashierMandatoryFieldsOverrides): void => {
    this.paymentService.setCashierMandatoryFields(cashierMandatoryFields);
    /* istanbul ignore next */
    this.checkoutService.checkout && this.updateComponent();
  };

  private readonly confirmPayment = async ({
    chargeResponseToken,
    newApi,
  }: {
    chargeResponseToken: string;
    newApi: boolean;
  }): ReturnType<typeof confirmPayment> => {
    return confirmPayment({
      chargeResponseToken,
      meta: {
        appDefId: this.flowAPI.controllerConfig.appParams.appDefinitionId,
        msid: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId as string,
        visitorId: this.flowAPI.controllerConfig.platformAPIs.bi?.visitorId as string,
        widgetId: StoresWidgetID.ECOM_PLATFORM_CHECKOUT,
      },
      biLoggerFactory: this.flowAPI.essentials.biLoggerFactory,
      redirectFn: this.flowAPI.controllerConfig.wixCodeApi.location.to!,
      newApi,
    });
  };

  public toProps(): PaymentStoreProps {
    const currentLanguage = getCurrentLanguage(this.flowAPI);
    const country2 =
      this.checkoutService.checkout.billingInfo?.address.country ??
      this.checkoutService.checkout.shippingDestination?.address.country ??
      'US';
    const country3 = getCountryFromLocaleDataset(country2)?.key;
    const configuration: PaymentsWidgetConfiguration = {
      locale: this.flowAPI.translations.config.language,
      appId: this.flowAPI.controllerConfig.appParams.appDefinitionId,
      appInstanceId: this.flowAPI.controllerConfig.appParams.instanceId,
      amount: `${this.checkoutService.checkout.payNowTotalAfterGiftCard.amount}`,
      currency: this.checkoutService.checkout.currency!,
      msid: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId as string,
      appInstance: this.flowAPI.controllerConfig.appParams.instance,
      visitorId: this.flowAPI.controllerConfig.platformAPIs.bi?.visitorId,
      siteOwnerId: this.flowAPI.controllerConfig.platformAPIs.bi?.ownerId,
      lang: this.flowAPI.translations.config.language,
      contactId: this.checkoutService.checkout.buyerInfo.contactId,
      viewMode: 'Site',
      country: country3,
      isPrimaryLanguage: currentLanguage?.isPrimaryLanguage ?? true,
      isSignedInUser: this.memberService.isMember(),
      companyName: this.checkoutSettingsService.checkoutSettings.storeName,
    };

    return {
      cashierWidgetProps: {
        configuration,
        locale: this.flowAPI.translations.config.language,
        theme: 'default',
        shouldApplySiteStyles: false,
        externalSubmitButton: true,
        walletPaymentInNewTab: false,
        allowManualPayment: this.flowAPI.experiments.enabled(SPECS.UseSettingsOverridesForPaylinks)
          ? !this.checkoutService.checkout.customSettings?.disableManualPayment
          : true,
        isSaveCCEnabled: this.navigationService.isMyWalletAppInstalled,
        allowRecurringPaymentOnly: this.checkoutService.checkout.hasSubscriptionItems,
        allowMerchantToSaveAndUsePM: this.checkoutSettingsService.checkoutSettings.mitEnabled,
        ...(this.flowAPI.experiments.enabled(SPECS.SupportCardTokenizationOnCartAndCheckout)
          ? {setupFutureUsages: this.checkoutService.checkout.isCardTokenizationCheckout}
          : {}),
        ...(this.flowAPI.experiments.enabled(SPECS.SupportDelayedCaptureOnCheckoutAndExpressButtons)
          ? {delayedCapture: this.checkoutSettingsService.checkoutSettings.delayCaptureEnabled}
          : {}),
      },
      cashierConfiguration: configuration,
      cashierMandatoryFields: this.paymentService.cashierMandatoryFields,
      expressPaymentMethodsAmount: this.paymentService.expressPaymentMethodsAmount,
      setCashierMandatoryFields: this.setCashierMandatoryFields,
      shouldRequirePayment:
        this.checkoutService.checkout.payNowTotalAfterGiftCard.amount > 0 ||
        this.checkoutService.checkout.isCardTokenizationCheckout,
      ...this.observe({confirmPayment: this.confirmPayment}),
    };
  }
}
